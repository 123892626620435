import React, {type ReactElement, useState} from 'react'
import PageLayout from "../../UIv2/PageLayout/PageLayout";
import IconButton from "../../UIv2/Button/IconButton";
import PageSearchbar from "../../UIv2/PageSearchbar/PageSearchbar";
import PageHeader from "../../UIv2/PageHeader/PageHeader";
import {useAppSelector} from "../../../../hooks/redux";
import LandlordUnitCard from "./UnitCards/LandlordUnitCard/LandlordUnitCard";
import {nanoid} from "nanoid";
import InfoBlock from "../../UIv2/InfoBlock/InfoBlock";
import useMyUnits from "./MyUnits.hook";
export default function MyUnitsV2 (): ReactElement {
    const [searchString, setSearchString] = useState('')
    const [showInfoBlock, setShowInfoBlock] = useState(true)
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const {myUnits} = useMyUnits()
    return <PageLayout>
        <PageHeader
            title={'My Units'}
            controls={
                <div className={'d-flex align-items-center gap-2'}>
                    <IconButton icon={'add'} style={'bso'}/>
                    <IconButton icon={'more_vert'} style={'gray'}/>
                </div>
            }
            horizontalControls={
                <div className={'mb-8'}>
                    <PageSearchbar
                        searchString={searchString}
                        setSearchString={setSearchString}
                    />
                </div>
            }
        />
        <div className={'overflow-auto'}
            style={{
                height: `calc(100vh - ${userDevice === 'IPhone' ? '21.5rem' : '20.3rem'})`
            }}
        >
            <div className={'d-flex flex-column gap-8'} style={{
                height: 'fit-content'
            }}>
                <InfoBlock
                    title={'Do you want fast results?'}
                    text={'Get BSO’s Property Management Contract (PMC) for better results. Add a new service below to get started!'}
                    isShown={showInfoBlock}
                    setIsShown={setShowInfoBlock}
                />
                {
                    myUnits.map((unit) => (
                        <LandlordUnitCard key={nanoid()} unit={unit}/>
                    ))
                }
            </div>
        </div>

    </PageLayout>
}