import React, {type ReactElement} from "react";
import {type IconName} from "../../../../app/icon/iconsConfig";
import Icon from "../../../../app/icon/Icon";

export type BadgeStyle = 'bso' | 'light-gray' | 'gray' | 'dark-gray' | 'dark' | 'danger'

interface BadgeProps {
    text: string
    icon?: {
        position: 'start' | 'end',
        name: IconName,
        filled?: boolean
    }
    style: BadgeStyle,
    size: '13' | '18' | '22' | '24',
    key?: string
}

export default function Badge ({text, icon, style, size, key}: BadgeProps) : ReactElement {
    let className = ''
    switch (style) {
        case 'bso':
            className = 'd-flex align-items-center bg-bso text-dark lh-1'
            break
        case 'light-gray':
            className = 'd-flex align-items-center bg-gray-200 text-gray-600 lh-1'
            break
        case 'gray':
            className = 'd-flex align-items-center bg-secondary text-dark lh-1'
            break
        case 'dark-gray':
            className = 'd-flex align-items-center bg-gray-700 text-white lh-1'
            break
        case 'dark':
            className = 'd-flex align-items-center bg-dark text-white lh-1'
            break
        case 'danger':
            className = 'd-flex align-items-center bg-danger text-white lh-1'
            break
        default:
            break
    }
    let styles = {}
    switch (size) {
        case '13':
            styles = {
                height: '13px',
                borderRadius: '100px'
            }
            className += ' fs-9 fw-bold py-1 px-2 gap-1'
            break
        case '18':
            styles = {
                height: '18px',
                borderRadius: '5px'
            }
            className += ' fs-9 fw-medium px-2 py-1 gap-1'
            break
        case '22':
            styles = {
                height: '22px',
                borderRadius: '5px'
            }
            className += ' fs-8 fw-medium px-3 py-2 gap-1'
            break
        case '24':
            styles = {
                height: '24px',
                borderRadius: '16px'
            }
            className += ' fs-7 px-4 gap-2'
            break
        default:
            break
    }
    return <div className={className} style={styles} key={key}>
        {
            icon?.position === 'start'
                ? <Icon name={icon.name} filled={icon.filled} className={'fs-7'}/>
                : <></>
        }
        <span>
            {text}
        </span>
        {
            icon?.position === 'end'
                ? <Icon name={icon.name} filled={icon.filled} className={'fs-7'}/>
                : <></>
        }
    </div>
}