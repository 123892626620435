import {useAppSelector} from "../../../../hooks/redux";
import { type Unit } from "../../../../store/slices/unitsSlice";

type IUseMyUnits = () => {
    myUnits: Unit[]
}

const useMyUnits: IUseMyUnits = () => {
    const units = useAppSelector((state) => state.units.units)
    const currentUser = useAppSelector((state) => state.user.currentUser)
    const myUnits = units.filter((unit) => unit.attributes?.User?.data?.id === currentUser.id)
    console.log(myUnits)
    return {
        myUnits
    }
}

export default useMyUnits
