import React, {type ReactElement, type ReactNode} from "react";

interface PageHeaderProps {
    title: string
    controls?: ReactNode
    horizontalControls?: ReactNode
}

export default function PageHeader ({title, controls, horizontalControls}: PageHeaderProps): ReactElement {
    return <div>
        <div className={'d-flex align-items-center justify-content-between mb-8'}>
            <h1 className={'fs-3x fw-medium m-0'}>
                {
                    title
                }
            </h1>
            {
                controls
            }
        </div>
        {
            horizontalControls
        }
    </div>
}